import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Navbar from '../navbar';
import useFetch from '../../hooks/useFetch'; // Import the useFetch hook

const apiurl = process.env.REACT_APP_API_URL;

const ReviewPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const reservationData = location.state;
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // Use the useFetch hook to fetch class details
  const { data: classDetails, loading, error: fetchError } = useFetch(
    reservationData.classid ? `${apiurl}/api/classes/${reservationData.classid}` : null
  );

  const handleSubmit = async () => {
    try {
      await axios.post(`${apiurl}/api/booking`, reservationData);
      setSuccessMessage('Reservation submitted successfully!');
      setTimeout(() => {
        navigate('/');
      }, 3000); // Redirect after 3 seconds
    } catch (error) {
      console.error('Error submitting reservation:', error);
      const errorMessage = error.response?.data?.error || 'Your selected dates are unavailable. Please try again.';
      setErrorMessage(errorMessage);
    }
  };

  const handleEdit = () => {
    navigate('/reserve', { 
      state: {
        ...reservationData,
        user: {
          _id: reservationData.userId,
          name: reservationData.name,
          email: reservationData.email
        },
        date: reservationData.date.map(dateString => new Date(dateString))
      }
    });
  };

  if (loading) return <div>Loading...</div>;
  if (fetchError) return <div>Error: {fetchError.message}</div>;

  return (
    <div>
      <Navbar />
      <div style={styles.container}>
        <h2 style={styles.title}>Review Your Reservation</h2>
        {successMessage && <p style={styles.success}>{successMessage}</p>}
        {errorMessage && <p style={styles.error}>{errorMessage}</p>}
        <div style={styles.reviewItem}>
          <strong>Name:</strong> {reservationData.name}
        </div>
        <div style={styles.reviewItem}>
          <strong>Email:</strong> {reservationData.email}
        </div>
        <div style={styles.reviewItem}>
          <strong>Phone Number:</strong> {reservationData.phonenumber}
        </div>
        <div style={styles.reviewItem}>
          <strong>Class:</strong> {classDetails?.name || 'N/A'}
        </div>
        <div style={styles.reviewItem}>
          <strong>Class Setting:</strong> {reservationData.classsetting || 'N/A'}
        </div>
        <div style={styles.reviewItem}>
          <strong>Location:</strong> {reservationData.location}
        </div>
        <div style={styles.reviewItem}>
          <strong>Time:</strong> {reservationData.time}
        </div>
        <div style={styles.reviewItem}>
          <strong>Dates:</strong> {reservationData.date.join(', ')}
        </div>
        <div style={styles.reviewItem}>
          <strong>Comments:</strong> {reservationData.additionalcomments}
        </div>
        <div style={styles.buttons}>
          <button onClick={handleSubmit} style={styles.button}>Submit</button>
          <button onClick={handleEdit} style={styles.button}>Edit</button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    width: '90%',
    maxWidth: '600px',
    margin: '20px auto',
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0 0 15px rgba(0, 0, 0, 0.2)',
  },
  title: {
    fontSize: '24px',
    marginBottom: '20px',
    color: '#333',
  },
  reviewItem: {
    marginBottom: '15px',
    fontSize: '18px',
    color: '#555',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginTop: '20px',
  },
  button: {
    flex: '1 1 48%',
    margin: '10px 1%',
    padding: '12px 24px',
    backgroundColor: '#A27707',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
    textAlign: 'center',
  },
  error: {
    color: 'red',
    marginTop: '20px',
    marginBottom: '20px',
    fontWeight: 'bold',
  },
  success: {
    color: 'green',
    marginTop: '20px',
    marginBottom: '20px',
    fontWeight: 'bold',
  },
};

export default ReviewPage;